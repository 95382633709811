import * as React from 'react'
import { graphql } from 'gatsby'
import InternalLayout from '../../../../components/InternalLayout'
import Seo from '../../../../components/Seo'
import {
  Flex,
  Box,
  Heading
} from '@chakra-ui/react'

import Sidebar from '../../../../components/Sidebar/Sidebar'
import MooseLottie from '../../../../components/Footer/Moose/MooseLottie'
import StripeFormWrapper from '../../../../components/Stripe/StripeFormWrapper'


const CateringApplicationPage = (props) => {
      // Explicitly delcare props and destructure the other properties:
      const { location, data } = props;
      const pageLocation = location.pathname;


  if (!data) return null; 

  const document = data.prismicCityClerkForm.data;
  const menuItems = null || document.sidebar_menu.document?.data.body;

  return (
    <InternalLayout>
      <Seo title={document.page_title.text} />
      <Box position="relative" zIndex="1">
      <Flex w="100%" h="100%" flexWrap="wrap" flexDirection={{base: 'column-reverse', lg: 'row'}}>
      <Box w={{base: '100%', lg: '25%'}}>
        <Sidebar
          menuHeading={menuItems && document.sidebar_menu.document.data.menu_title.text}  
          menuItems={menuItems}
          pageLocation={pageLocation}
        />
      </Box>
          <Box w={{base: '100%', lg: '75%'}} bg="white" p="8" style={{marginBottom: '36rem'}}>
            <Heading as="h2" variant="page-title">
              {document.page_title.text}
            </Heading>
            <Heading as="h3" fontSize="2rem" variant="page-subtitle">
              {document.subtitle.text}
            </Heading>
            
            <StripeFormWrapper 
            pageLocation={pageLocation}
           />
            
     </Box>
      <MooseLottie />
        </Flex>
      </Box>
      
    </InternalLayout>
  )
}

export const query = graphql`
  {
    prismicCityClerkForm(uid: {eq: "catering-application"}) {
      url
      uid
      data {
        subtitle {
          text
        }
        sidebar_menu {
          document {
            ... on PrismicSidebarNavigation {
              id
              data {
                menu_title {
                  text
                }
                body {
                  ... on PrismicSidebarNavigationDataBodySidebarNavAccordionItem {
                    id
                    slice_type
                    primary {
                      title_link {
                        url
                      }
                      navigation_group_title {
                        text
                      }
                    }
                    items {
                      link {
                        url
                      }
                      item_title {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
        page_title {
          text
        }
        page_content {
          richText
        }
        hero_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
          alt
          copyright
        }
      }
    }
  }
`

export default CateringApplicationPage;
